/* eslint-env jquery */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import CookieAlert from './cookies/CookieAlert';

const setup = () => {
  const hasAcceptedCookies = document.cookie.split(';').filter(cookie => cookie.includes('accepted_cookies=true')).length;

  if (!hasAcceptedCookies) {
    const alertContainer = document.createElement('div');
    alertContainer.setAttribute('id', 'cookieAlert');
    $('body').prepend(alertContainer);
    ReactDOM.render(<CookieAlert />, alertContainer);
  }
};

document.addEventListener('DOMContentLoaded', setup);

export default setup;
